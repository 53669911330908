import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "disable", "name", "claimSubmit" ]

  connect() {
    this.submitting = false
    if (this.hasNameTarget) {
      this.checkInput(this.nameTarget)
    }
  }

  check(event) {
    if (!this.submitting) {
      this.checkInput(event.currentTarget)
    }
  }

  checkInput(input) {
    if (input.value != "") {
      this.claimSubmitTarget.removeAttribute("disabled")
    } else {
      this.claimSubmitTarget.setAttribute("disabled", true)
    }
  }

  beforeSend() {
    this.submitting = true
    this.disableTargets.forEach( target => {
      target.setAttribute("disabled", true)
    })
  }
}
