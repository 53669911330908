import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "access-entry"
    document.querySelector(".remove-button").style.display = "none"
  }

  updateColorTextCheckbox(event) {
    if(event.currentTarget.selectedIndex == 0) {
      event.currentTarget.className = "form-control prompt"
    }
    else {
      event.currentTarget.className = "form-control selected"
    }
  }

  credentialsAction(event) {
    if (event.key === "Enter") {
      event.preventDefault()
      this.add_association(event)
      this._focusNextElement(event.currentTarget)
    } else if (event.key === "Backspace") {
      if (event.currentTarget.value === "" && this._numberOfActiveCredentials() > 1) {
        // Backspace on an empty text field
        this._focusPrevElement(event.currentTarget)
        this.remove_association(event)
      }
    }
  }

  _findPrevElement(elem) {
    var sibling = elem.previousElementSibling

    while (sibling) {
      if (sibling.matches("." + this.wrapperClass + ":not(.hidden)")) return sibling
      sibling = sibling.previousElementSibling
    }
  }

  _findNextElement(elem) {
    var sibling = elem.nextElementSibling

    while (sibling) {
      if (sibling.matches("." + this.wrapperClass + ":not(.hidden)")) return sibling
      sibling = sibling.nextElementSibling
    }
  }

  _focusPrevElement(target) {
    const input = this._findPrevElement(target.closest("." + this.wrapperClass)).querySelector("input[type=text]")
    const end = input.value.length
    input.setSelectionRange(end, end)
    input.focus()
  }

  _focusNextElement(target) {
    const input = this._findNextElement(target.closest("." + this.wrapperClass)).querySelector("input[type=text]")
    const end = input.value.length
    input.setSelectionRange(end, end)
    input.focus()
  }

  _numberOfActiveCredentials() {
    return document.querySelectorAll(".access-entry:not(.hidden)").length
  }

  add_association(event) {
    event.preventDefault()
    this.add_credential("")

    if (typeof firebase != 'undefined') {
      firebase.analytics().logEvent('credential_row_added');
    }
  }

  remove_association(event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.wrapperClass)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.classList.add("hidden")
    }
  }

  add_credential(credential) {
    var templateDOM = this.templateTarget.cloneNode(true)
    const input = templateDOM.content.querySelector("input[type=text]")
    const hiddenInput = templateDOM.content.querySelector("input[type=hidden]")
    
    const timestamp = Math.floor(Math.random() * 100000000) + 1
    input.setAttribute("value", credential)
    input.setAttribute("name", input.getAttribute("name").replace(/NEW_RECORD/g, timestamp))
    input.setAttribute("id", input.getAttribute("id").replace(/NEW_RECORD/g, timestamp))
    hiddenInput.setAttribute("name", hiddenInput.getAttribute("name").replace(/NEW_RECORD/g, timestamp))
    hiddenInput.setAttribute("id", hiddenInput.getAttribute("id").replace(/NEW_RECORD/g, timestamp))

    this.linksTarget.insertAdjacentHTML('beforebegin', templateDOM.innerHTML)
  }

  pasteFromExcel(event) {
    let data = event.clipboardData.getData("text")

    event.preventDefault()

    let rows = data.split("\n")
    var rowCount = rows.length
    var columnCount = 0

    for(var row in rows) {
      let cells = rows[row].split("\t")
      var credential = []

      columnCount = cells.length

      for(var cell in cells) {
        credential.push(cells[cell])
      }
      let credentials = credential.join("; ")
      if (row == 0) {
        console.log("!")
        event.currentTarget.value = credentials
      } else {
        this.add_credential(credentials)
      }
    }

    if (typeof firebase != 'undefined') {
      firebase.analytics().logEvent('excel_pasted', { rows: rowCount, columns: columnCount });
    }
  }
}
