import { Modal } from "tailwindcss-stimulus-components"

export default class extends Modal {
  static targets = ['msg', 'expl']

  connect() {
    this.confirmModalOpen = false
    this.currentConfirmationElement = null
    super.connect()
  }

  close(event) {
    super.close(event)
    this.confirmModalOpen = false
  }

  confirm(event) {
    this.currentConfirmationElement = event.currentTarget

    if (this.confirmModalOpen) {
      // calling confirm again while the modal is open can only mean
      // the user pressed confirm in our modal
      // => we do nothing here but close the modal
      this.close(event)
    } else {
      event.stopPropagation()
      event.preventDefault()

      this.msgTarget.innerHTML = event.params.msg

      if (event.params.expl) {
        this.explTarget.innerHTML = event.params.expl
        this.explTarget.classList.remove("hidden")
      } else {
        this.explTarget.classList.add("hidden")
      }

      super.open(event)

      this.confirmModalOpen = true
    }
  }

  commitConfirm(event) {
    // clicking the original link again
    this.currentConfirmationElement.click()
  }
}